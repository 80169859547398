<template>
  <div class="case-all-container">
    <div class="case-all-top">
      <div class="case-all-top-title">
        {{ $t('HR001.queryConditionTitle') }}
      </div>
      <div class="case-all-top-date">
        <a-range-picker
          style="width: 220px"
          :ranges="ranges"
          show-time
          v-model:value="fromToDatelist"
          format="YYYY/MM/DD"
          :disabled-date="disabledDate"
          @change="onChangeDate"
        />
      </div>
      <div class="case-all-top-group">
        <a-select
          mode="multiple"
          style="width: 220px"
          v-model:value="selectedGroup"
          :options="groupOptions"
          :placeholder="placeHolderChooseGroup"
        >
          <a-select-option
            v-for="item in groupOptions"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <!-- <div class="case-all-top-item">
        <a-select
          mode="multiple"
          v-model:value="selectedCondition"
          style="width: 320px"
          :placeholder="placeHolderChooseAbnormalItem"
          @change="onChangeCondition"
        >
          <a-select-option
            v-for="item in conditionOptions"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div> -->
      <div class="case-all-top-name">
        <a-input v-model:value="name" :placeholder="placeHolderChooseName" />
      </div>
      <div class="case-all-top-confirm">
        <a-button
          class="confirm-button"
          @click="onClickQueryBtn"
          shape="round"
          :disabled="isQueryBtnDisable"
        >
          {{ $t('HR001.query') }}
        </a-button>
      </div>
      <div class="case-all-top-export">
        <a-button
          class="export-button"
          @click="clickExport"
          :disabled="!isExportON"
          :loading="isExportLoading"
        >
          <template #icon><ExportOutlined /></template>
          {{ $t('HR001.export') }}
        </a-button>
      </div>
    </div>
    <div class="case-all-detail">
      <!-- <div class="case-list-top">
        <div class="new-case-btn">
          <a-button @click="clickExport" :disabled="!isExportON">
            <template #icon><ExportOutlined /></template>
            {{ $t('HR001.export') }}
          </a-button>
        </div>
      </div> -->
      <MeasurementsAllTable
        :fromDate="fromDate"
        :toDate="toDate"
        :pageSize="pagnation.size"
        @onPageChange="onPageChange"
      />
    </div>
  </div>
</template>
<script>
  import dayjs from 'dayjs'
  import moment from 'moment'
  import { mapActions, mapGetters } from 'vuex'
  import MeasurementsAllTable from './components/MeasurementsAllTable'
  import { getMeasurementsExport } from '@/api/measurement'
  import { ExportOutlined } from '@ant-design/icons-vue'
  import { message } from 'ant-design-vue'

  export default {
    name: 'Ｍeasurements',
    components: { MeasurementsAllTable, ExportOutlined },
    data() {
      const ranges = {}
      ranges[this.$t('DatePicker.today')] = [moment(), moment()]
      ranges[this.$t('DatePicker.yesterday')] = [
        moment().add(-1, 'days'),
        moment().add(-1, 'days'),
      ]
      ranges[this.$t('DatePicker.thisMonth')] = [
        moment().startOf('month'),
        moment().endOf('month'),
      ]
      ranges[this.$t('DatePicker.latestMonth')] = [
        moment().add(-1, 'months'),
        moment(),
      ]
      ranges[this.$t('DatePicker.latestThreeMonths')] = [
        moment().add(-3, 'months'),
        moment(),
      ]
      ranges[this.$t('DatePicker.latestYear')] = [
        moment().add(-1, 'years').add(1, 'days'),
        moment(),
      ]
      return {
        placeHolderChooseGroup: this.$t('HR001.chooseGroup'),
        placeHolderChooseName: this.$t('HR001.chooseName'),
        // placeHolderChooseAbnormalItem: this.$t('HR001.chooseAbnormalItem'),
        fromToDatelist: [],
        fromDate: 0,
        toDate: 0,
        ranges: ranges,
        selectedGroup: [],
        groupOptions: [],
        selectedCondition: [],
        // conditionOptions: [
        //   {
        //     value: '0',
        //     label: this.$t('HR001.conditionAll'),
        //   },
        //   {
        //     value: '1',
        //     label: this.$t('HR001.conditionBloodPressure-1'),
        //   },
        //   {
        //     value: '2',
        //     label: this.$t('HR001.conditionBloodPressure-2'),
        //   },
        //   {
        //     value: '3',
        //     label: this.$t('HR001.conditionGlucose-1'),
        //   },
        //   {
        //     value: '4',
        //     label: this.$t('HR001.conditionGlucose-2'),
        //   },
        //   {
        //     value: '5',
        //     label: this.$t('HR001.conditionBodyWeight-1'),
        //   },
        //   {
        //     value: '6',
        //     label: this.$t('HR001.conditionBodyWeight-2'),
        //   },
        //   {
        //     value: '7',
        //     label: this.$t('HR001.conditionBodyFat-1'),
        //   },
        //   {
        //     value: '8',
        //     label: this.$t('HR001.conditionBodyFat-2'),
        //   },
        //   {
        //     value: '9',
        //     label: this.$t('HR001.conditionVisceralFat-1'),
        //   },
        //   {
        //     value: '10',
        //     label: this.$t('HR001.conditionVisceralFat-2'),
        //   },
        //   {
        //     value: '11',
        //     label: this.$t('HR001.conditionSpo2-1'),
        //   },
        //   {
        //     value: '12',
        //     label: this.$t('HR001.conditionSpo2-2'),
        //   },
        //   // {
        //   //   value: 'bodyTemperature-1',
        //   //   label: this.$t('HR001.conditionBodyTemperature-1'),
        //   // },
        //   // {
        //   //   value: 'bodyTemperature-2',
        //   //   label: this.$t('HR001.conditionBodyTemperature-1'),
        //   // },
        // ],
        pagnation: {
          size: 10,
          number: 1,
        },
        name: '',
        isExportLoading: false,
      }
    },
    computed: {
      ...mapGetters({
        groups: 'group/groups',
        conditionForMeasurementDataAll:
          'measurement/conditionForMeasurementDataAll',
      }),
      isQueryBtnDisable() {
        let isQueryBtnDisable = true
        if (this.fromDate > 0 && this.toDate > 0) {
          isQueryBtnDisable = false
        }

        return isQueryBtnDisable
      },
      isExportON() {
        if (this.fromDate == 0 && this.toDate == 0) {
          return false
        }
        return true
      },
    },
    // updated() {
    //   console.log('==updated')
    // },
    // created() {
    //   console.log('==created')
    // },
    mounted() {
      this.clearMeasurementDataAll()
      if (
        this.conditionForMeasurementDataAll &&
        this.conditionForMeasurementDataAll.timeRange &&
        this.conditionForMeasurementDataAll.timeRange.startTime > 0 &&
        this.conditionForMeasurementDataAll.timeRange.endTime > 0
      ) {
        this.fromToDatelist.push(
          moment(this.conditionForMeasurementDataAll.timeRange.startTime)
        )
        this.fromToDatelist.push(
          moment(this.conditionForMeasurementDataAll.timeRange.endTime)
        )
        this.fromDate = this.conditionForMeasurementDataAll.timeRange.startTime
        this.toDate = this.conditionForMeasurementDataAll.timeRange.endTime
      } else {
        const today = moment()
        const threeMonthAgo = moment().add('M', '-3')
        this.fromDate = threeMonthAgo.startOf('day').unix() * 1000
        this.toDate = today.endOf('day').unix() * 1000
        this.fromToDatelist = []
        this.fromToDatelist.push(threeMonthAgo)
        this.fromToDatelist.push(today)
        this.onClickQueryBtn()
      }
      this.getGroupList()
      this.getMeasureThreshold()
    },
    methods: {
      ...mapActions({
        getGroupList: 'group/getGroupList',
        getMeasurementDataAll: 'measurement/getMeasurementDataAll',
        clearMeasurementDataAll: 'measurement/clearMeasurementDataAll',
        getMeasureThreshold: 'measureThreshold/getMeasureThreshold',
      }),
      updateGroupOptions() {
        this.groupOptions = []
        if (!this.groups) {
          return
        }

        this.groups.forEach((item) => {
          this.groupOptions.push({
            value: item.groupId.toString(),
            label: item.title,
          })
        })
      },
      onChangeCondition(selectedCondition) {
        for (let i = 0; i < selectedCondition.length; i++) {
          if (selectedCondition[i] === '0') {
            this.selectedCondition = ['0']
            return
          }
        }
        console.log(selectedCondition)
      },
      onChangeDate(date, dateString) {
        if (dateString && dateString.length == 2) {
          const fromDate = dayjs(dateString[0]).startOf('day').unix()
          const toDate = dayjs(dateString[1]).endOf('day').unix()
          console.log(fromDate)
          console.log(toDate)
          this.fromDate = fromDate * 1000
          this.toDate = toDate * 1000
        }
      },
      onClickQueryBtn() {
        if (this.fromDate <= 0 || this.toDate <= 0) {
          return
        }
        const postBody = {
          timeRange: {
            startTime: this.fromDate,
            endTime: this.toDate,
          },
        }

        if (this.selectedGroup.length > 0) {
          postBody.groupIds = []
          this.selectedGroup.forEach((item) => {
            postBody.groupIds.push(parseInt(item, 10))
          })
        }

        if (this.selectedCondition.length > 0) {
          postBody.abnormalConditions = []
          for (let i = 0; i < this.selectedCondition.length; i++) {
            let numValue = parseInt(this.selectedCondition[i], 10)
            if (isNaN(numValue) === false && numValue === 0) {
              postBody.abnormalConditions = [0]
              break
            }
            if (isNaN(numValue) && numValue > 0) {
              postBody.abnormalConditions.push(numValue)
            }
          }
        }
        if (this.name) {
          postBody.name = this.name
        }
        postBody.pagnation = this.pagnation
        console.log(postBody)
        this.getMeasurementDataAll(postBody)
      },
      async clickExport() {
        this.isExportLoading = true
        try {
          if (this.fromDate == 0 && this.toDate == 0) {
            return
          }
          const params = {
            startTime: this.fromDate,
            endTime: this.toDate,
          }
          if (this.name) {
            params.name = this.name
          }
          if (this.selectedGroup.length > 0) {
            params.groups = this.selectedGroup.join(',')
            // params.groups = []
            // this.selectedGroup.forEach((item) => {
            //   params.groups.push(parseInt(item, 10))
            // })
          }

          params.lang = this.$i18n.locale

          try {
            const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (tzString) {
              params.tz = tzString
            } else {
              message.success(this.$t('HR001.timezoneWarning'))
            }
          } catch (e) {
            message.success(this.$t('HR001.timezoneWarning'))
            console.log(e)
          }

          const respData = await getMeasurementsExport(params)
          // console.log(respData)
          // console.log(respData.headers['Content-Disposition'])
          // console.log(respData.headers['content-disposition'])

          let fileName = 'Report.xlsx'
          let contentDisposition = respData.headers['content-disposition']
          if (!contentDisposition) {
            contentDisposition = respData.headers['Content-Disposition']
          }
          if (contentDisposition) {
            fileName = contentDisposition.split(' ')[1].replace('filename=', '')
          }

          const url = window.URL.createObjectURL(new Blob([respData.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          this.isExportLoading = false
        } catch (e) {
          this.isExportLoading = false
          console.log(e)
        }
      },
      disabledDate(current) {
        return current && current < moment().add(-1, 'years')
      },
      onPageChange(inPagination) {
        this.pagnation.number = inPagination.current
        this.onClickQueryBtn()
      },
    },
    watch: {
      groups: function () {
        this.updateGroupOptions()
      },
    },
  }
</script>
<style lang="less" scoped>
  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .case-all-container {
    padding-top: 28px;
    padding-left: 31px;
    padding-right: 31px;
    background-color: #e5e5e5;

    .case-all-top {
      .clear-fix-float();
      //margin-bottom: 20px;
      //height: 100px;
      min-height: 100px;
      height: auto;
      background-color: #ffffff;
      border-radius: 10px;

      .case-all-top-title {
        width: 72;
        height: 32;
        float: left;
        margin-top: 40px;
        margin-left: 20px;
        padding-top: 4px;
        padding-bottom: 4px;
        font-family: Noto Sans TC;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 16px;
        /* identical to box height, or 89% */

        display: flex;
        align-items: center;

        color: rgba(0, 0, 0, 0.5);
      }
      .case-all-top-date {
        width: 226;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-group {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-name {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-item {
        width: 195;
        height: 16;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
      }
      .case-all-top-confirm {
        width: 85;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .confirm-button {
          color: white;
          background: #4dbaa7;
          border-radius: 6px;
          &:focus {
            -webkit-appearance: none;
            outline: none;
            box-shadow: none;
          }
        }
      }
      .case-all-top-export {
        width: 110px;
        height: 40;
        float: left;
        margin-top: 40px;
        margin-left: 15px;
        .export-button {
          &:focus {
            -webkit-appearance: none;
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.85);
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    .case-all-detail {
      .clear-fix-float();
      margin-top: 20px;
      //border-radius: 25px;
      //background-color: cornflowerblue;
      width: 100%;
      //height: 200px;
      background: #ffffff;

      .case-list-top {
        height: 75px;
        .clear-fix-float();
        .new-case-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
      }
    }
  }
</style>
